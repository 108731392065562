import React, { useContext } from "react";
import FloodAlertIcon from "./FloodAlertIcon";
import Choices from "./Choices";
import { Context } from "../Context";
import "../styles/FloodAlertList.scss";
import IconComponent from "./IconComponent";
import {
  faFilter,
  faInfoCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
function FloodAlertList(props) {
  const { setup, setSetup, filter, setFilter } = useContext(Context);

  const { nolonger, ...levelOrder } = setup.levelOrder;
  console.log("setup filter", setup);
  const uniqueValues = () => {
    var myUniqueValues = {};
    for (var label in filter.keys) {
      myUniqueValues[label] = [];

      var uniqueValueKeys = {};

      for (var key in setup.warnings) {
        if (setup.shapes[key] != undefined) {
          if (setup.shapes[key][filter.keys[label].source] != undefined) {
            var myValue =
              setup.shapes[key][filter.keys[label].source].split(", ");
            for (var i = 0; i < myValue.length; i++) {
              if (uniqueValueKeys[myValue[i]] == undefined) {
                uniqueValueKeys[myValue[i]] = 0;
              }
              uniqueValueKeys[myValue[i]] += 1;
            }
          }
        }
      }

      for (var key2 in uniqueValueKeys) {
        myUniqueValues[label].push({
          value: key2,
          count: uniqueValueKeys[key2],
        });
      }

      myUniqueValues[label].sort(function (a, b) {
        if (a.count > b.count) {
          return -1;
        } else if (a.count < b.count) {
          return 1;
        } else {
          if (a.value > b.value) {
            return 1;
          } else if (a.value < b.value) {
            return -1;
          } else {
            return 0;
          }
        }
      });
    }

    return myUniqueValues;
  };

  const filteredResults = () => {
    var myFilteredResults = {};
    for (var key in setup.levels) {
      var thisLevel = {
        count: 0,
        warnings: {},
      };

      for (var key2 in setup.warnings) {
        if (setup.warnings[key2].severityLevel == setup.levels[key].level) {
          var includeResult = true;

          if (setup.shapes[key2] != undefined) {
            for (var key3 in filter.keys) {
              if (filter.keys[key3].filter.length > 0) {
                var thisInclude = false;
                for (var i = 0; i < filter.keys[key3].filter.length; i++) {
                  //Below if condition added by Arjit to fix filter issue on 10th Nov 2023
                  if (
                    setup.shapes[key2][filter.keys[key3].source] != undefined
                  ) {
                    var resultValue =
                      setup.shapes[key2][filter.keys[key3].source].split(", ");
                    if (
                      resultValue.indexOf(filter.keys[key3].filter[i]) != -1
                    ) {
                      // CHANGE TO CHECK IN LIST
                      thisInclude = true;
                    }
                  }
                }
                if (thisInclude == false) {
                  includeResult = false;
                }
              }
            }
          }

          if (includeResult == true) {
            thisLevel.warnings[key2] = setup.warnings[key2];
            thisLevel.count += 1;
          }
        }
      }

      myFilteredResults[key] = thisLevel;
    }
    /* With below destructuring with rest operator we are removing "nolonger" values from myFilteredResults array
    To ensure we dont have to show any warnings for "no longer in force" warnings under warnings list menu */
    const { nolonger, ...filteredResExceptNoLonger } = myFilteredResults;
    //return myFilteredResults; //Commented as we need results other than no longer only.
    return filteredResExceptNoLonger;
  };

  const filterCount = () => {
    var myCount = 0;
    for (var key3 in filter.keys) {
      if (filter.keys[key3].filter.length > 0) {
        myCount += 1;
      }
    }
    return myCount;
  };

  const goFilter = (flt, data) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      keys: {
        ...prevFilter.keys,
        [flt.source]: {
          ...prevFilter.keys[flt.source],
          filter: [...data],
        },
      },
    }));
  };

  const showDetail = (item, k2) => {
    setSetup((prevSetup) => ({
      ...prevSetup,
      menu: {
        ...prevSetup.menu,
        detail: k2,
      },
      snippets: [k2],
      //showSnippet: true,
    }));
    console.log("k2 is", k2);
    console.log("item is", item);
  };

  return (
    <div className="listview">
      <div className="fixed">
        <div className="topblock"></div>
        <div className="list sticky">
          <div
            className="item filter clickable"
            onClick={() =>
              setFilter((prevFilter) => ({
                ...prevFilter,
                menu: !prevFilter.menu,
              }))
            }
          >
            <h3>
              <IconComponent icon={faFilter} /> Filter
              {filterCount() > 0 && (
                <span>
                  {" "}
                  ({filterCount()} active filter{filterCount() > 1 && "s"})
                </span>
              )}
            </h3>
          </div>
        </div>
      </div>

      <div
        className="scrollable"
        style={{ display: filter.menu ? "block" : "none" }}
      >
        <div className="list">
          {Object.entries(filter.keys).map(([label, flt]) => (
            <div className="item filterlist" key={flt.source}>
              <h4>{label}</h4>
              <div className="itembox">
                <Choices
                  values={uniqueValues()[label]}
                  selected={flt.filter}
                  fltLabel={label}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div
        className="scrollable"
        style={{ display: filter.menu ? "none" : "block" }}
      >
        {setup.levelOrder.map((level, idx) => (
          <div key={idx} className="list">
            <div className="item header sticky2">
              <img
                src={require(`../assets/icons/${setup.levels[level].icon}`)}
                alt="icon"
              />
              <h2>{setup.levels[level].label}</h2>
            </div>
            {filteredResults()[level] &&
              filteredResults()[level].count === 0 && (
                <div className="item">
                  <img
                    src={require(`../assets/icons/${setup.levels["nolonger"].icon}`)}
                    alt="icon"
                  />
                  <h2>
                    There are currently no {setup.levels[level].label} in force
                  </h2>
                </div>
              )}

            {filteredResults()[level] &&
              Object.entries(filteredResults()[level]?.warnings).map(
                ([item, k2]) => (
                  <div
                    className="item clickable"
                    key={k2}
                    onClick={() =>
                      showDetail(filteredResults()[level].warnings[item], k2)
                    }
                  >
                    {setup.levels[level] != "nolonger" && (
                      <img
                        src={require(`../assets/icons/${setup.levels[level].icon}`)}
                        alt="icon"
                      />
                    )}
                    {setup.shapes[item] !== undefined && (
                      <h2>{setup.shapes[item].label}</h2>
                    )}
                  </div>
                )
              )}
          </div>
        ))}
        <div className="bottomBlock"></div>
      </div>

      <div
        className="fixed"
        style={{ display: filter.menu ? "block" : "none" }}
      >
        <div className="list">
          <div
            className="item filter clickable bottom"
            onClick={() =>
              setFilter((prevFilter) => ({ ...prevFilter, menu: false }))
            }
          >
            <h3>
              <IconComponent icon={faCheckCircle} /> Apply filters
            </h3>
          </div>
        </div>
      </div>

      <div
        className="fixed"
        style={{ display: filter.menu ? "none" : "block" }}
      >
        <div className="list">
          <div
            className="item filter clickable bottom"
            onClick={() => {
              setSetup((prevSetup) => ({
                ...prevSetup,
                settings: { ...prevSetup.settings, menu: true },
              }));
              setFilter((prevFilter) => ({ ...prevFilter, menu: false }));
            }}
          >
            <h3>
              <IconComponent icon={faInfoCircle} /> About
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FloodAlertList;
