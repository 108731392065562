import React, { useState, useEffect, useContext } from "react";
import "../styles/Summary.scss";
import FloodAlertCount from "./FloodAlertCount";
import { Context } from "../Context";

const FloodAlertSummary = ({ props }) => {
  const { setup, setSetup, filter, setFilter } = useContext(Context);

  return (
    <div className="summaryHolder">
      <div className="summary panel">
        <FloodAlertCount props={setup} type="alert" />
        <div className="bar"></div>
        <FloodAlertCount props={setup} type="warning" />
        <div className="bar"></div>
        <FloodAlertCount props={setup} type="severe" />
        {/* <div className="bar"></div>
        <FloodAlertCount props={setup} type="nolonger" /> */}
      </div>
    </div>
  );
};

export default FloodAlertSummary;
