import React, { useContext, useEffect } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import FloodAlertIcon from "./FloodAlertIcon";
import { Context } from "../Context";
import "../styles/FloodAlertSnippet.scss";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";

const FloodAlertSnippet = () => {
  const { setup, setSetup, filter, setFilter } = useContext(Context);
  const getLevel = (warning) => {
    let myLevel = {};
    for (const key2 in setup.levels) {
      if (setup.levels[key2].level === warning.severityLevel) {
        myLevel = setup.levels[key2];
      }
    }

    return myLevel;
  };

  useEffect(() => {}, [setup.snippets]);

  const snippets = setup.snippets
    .filter((snippet) => setup.warnings[snippet.id] !== undefined)
    .map((snippet) => setup.warnings[snippet.id]);

  return (
    <div className="snippet panel">
      <div className="list">
        <TransitionGroup component={null} name="list">
          {snippets.map((warning, id) => (
            <CSSTransition key={id} timeout={500} classNames="list">
              <div
                className="item clickable"
                onClick={() => {
                  setSetup((prevSetup) => ({
                    ...prevSetup,
                    menu: { active: true, detail: warning },
                    showSnippet: false,
                  }));
                }}
              >
                <img
                  src={require(`../assets/icons/${getLevel(warning).icon}`)}
                  alt=""
                />
                {setup.shapes[warning.id] !== undefined && (
                  <h2>{setup.shapes[warning.id].label}</h2>
                )}
                <h2>
                  <FloodAlertIcon icon={faArrowCircleRight} />
                </h2>
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    </div>
  );
};

export default FloodAlertSnippet;
