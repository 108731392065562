import React, { useState, useEffect, useContext } from "react";
import FloodAlertMap from "./components/FloodAlertMap";
import FloodAlertSearch from "./components/FloodAlertSearch";
import FloodAlertSummary from "./components/FloodAlertSummary";
import FloodAlertMenu from "./components/FloodAlertMenu";
import FloodAlertSnippet from "./components/FloodAlertSnippet";
import queryString from "query-string";
import PouchDB from "pouchdb";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { Context } from "./Context";
import "./styles/common.scss";
import Intro from "./components/Intro";

function App() {
  const { setup, setSetup, filter, setFilter, iSettings, setISettings } =
    useContext(Context);

  var pdb;
  var stdb;
  var gdb;

  pdb = new PouchDB("floodalert");
  stdb = new PouchDB("settings");
  // Your web app's Firebase configuration
  var firebaseConfig = {
    apiKey: "AIzaSyB7U_pgxGW46WfzPjNZNVH5BpCg6zXJaNs",
    authDomain: "flood-alert-server.firebaseapp.com",
    databaseURL: "https://flood-alert-server.firebaseio.com",
    projectId: "flood-alert-server",
    storageBucket: "flood-alert-server.appspot.com",
    messagingSenderId: "808830596064",
    appId: "1:808830596064:web:bb7c4ebb56f7dcbf5bb185",
    measurementId: "G-VY60LLHX9T",
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  gdb = firebase.firestore();

  useEffect(() => {
    var fa = this;

    stdb.allDocs({ include_docs: true, descending: true }, function (err, doc) {
      if (doc.rows && doc.rows.length > 0) {
        setISettings((prevSetup) => {
          return {
            ...prevSetup,

            rev: doc.rows[0].doc._rev,
            intro: doc.rows[0].doc.intro,
          };
        });
      } else {
        getLocation()
          .then(handleGeolocationSuccess)
          .catch(handleGeolocationError);
        console.log("Appjs no doc geolocation", setup.geolocation.lon);

        saveSettings();
      }
      if (
        doc &&
        doc.rows &&
        doc.rows.length > 0 &&
        doc.rows[0].doc //&&
        // doc.rows[0].doc.intro === false  commented as when PDB update error occurs geolocation is not captured
      ) {
        getLocation()
          .then(handleGeolocationSuccess)
          .catch(handleGeolocationError);
      }
    });

    pdb.allDocs({ include_docs: true, descending: true }, function (err, doc) {
      setSetup((prevSetup) => {
        const updatedShapes1 = { ...prevSetup.shapes }; // Create a temporary object

        for (var i = 0; i < doc.rows.length; i++) {
          const docId = doc.rows[i].doc._id;
          updatedShapes1[docId] = doc.rows[i].doc;
        }

        return { ...prevSetup, shapes: updatedShapes1 }; // Return the updated state
      });

      //var warningsTable = "warnings"; //commented this as this is the table created from v1 code written by Joe

      var warningsTable = "warningsNew"; //New table created as per new firebase function created and deployed as part of v2

      //Parsing the query parmater whether any param is present or not
      const parsed = queryString.parse(window.location.search);

      //**************************************************************************************** */
      //Below condition is added to refer "warningstest" table in firebase created for test environment
      //So when we pass URL where test=true or any value to test indicate query param is test
      //Example URL to point to test environment- http://localhost:3000/?test=true

      if (parsed.test != undefined) {
        warningsTable = "warningstest";
      }

      //**************************************************************************************** */
      gdb
        .collection(warningsTable)
        .get()
        .then((querySnapshot) => {
          setSetup((prevSetup) => {
            return { ...prevSetup, loaded: true };
          });

          var warningList = [];

          querySnapshot.forEach((doc) => {
            setSetup((prevSetup) => {
              return {
                ...prevSetup,
                warningsToLoad: prevSetup.warningsToLoad + 1,
              };
            });
            warningList.push(doc.data());
          });

          warningList.sort(function (a, b) {
            return b.severityLevel - a.severityLevel;
          });

          getWarnings(warningList);
        });
    });
  }, []);

  //Watchers
  useEffect(() => {
    if (setup?.position) {
      if (setup.position.zoom >= setup.polygonMinZoom) {
        var view = {
          left: setup.position.bounds[0][0],
          right: setup.position.bounds[1][0],
          bottom: setup.position.bounds[0][1],
          top: setup.position.bounds[1][1],
        };

        var polygons = [];

        for (var key in setup.shapes) {
          var floodArea = setup.shapes[key];

          if (floodArea.bounds != undefined) {
            if (
              floodArea.bounds.right > view.left &&
              floodArea.bounds.left < view.right &&
              floodArea.bounds.top > view.bottom &&
              floodArea.bounds.bottom < view.top
            ) {
              if (setup.shapes[key].polygon === undefined) {
                polygons.push(key);
              }
            }
          }
        }

        //TEMPORARILY REMOVED
        /*if (polygons.length > 0) {

						this.getFloodAreaPolygons(polygons);

					}*/

        //51.805813, -2.284227

        /*var docRef = gdb.collection("floodAreas")
						.where('bounds.left', '<', view.right)
						.where('bounds.right', '>', view.left)
						.where('bounds.bottom', '<', view.top)
						.where('bounds.top', '>', view.bottom);
					
					docRef.get().then(function(docs) {
						console.log(docs);
					});*/
      }
    }
  }, [setup?.position]);

  useEffect(() => {
    if (setup.warningPolygonsQueue?.length > 0) {
      var polygons = [];
      for (var i = 0; i < setup.warningPolygonsQueue.length; i++) {
        polygons.push(setup.warningPolygonsQueue[i]);
      }
      setup.warningPolygonsQueue.splice(0);

      if (polygons.length > 0) {
        //TEMPORARILY REMOVED
        //this.getFloodAreaPolygons(polygons);
      }
    }
  }, [setup?.warningPolygonsQueue]);

  useEffect(() => {
    if (!iSettings.intro) {
      setTimeout(() => {
        saveSettings();
      }, "1000");
    }
  }, [iSettings?.intro]);

  //Methods---------------------------------------------------

  function getWarnings(warningList) {
    setSetup((prevSetup) => {
      const updatedWarnings = { ...prevSetup.warnings };

      warningList.forEach((thisWarning) => {
        updatedWarnings[thisWarning.id] = thisWarning;

        if (prevSetup.shapes[thisWarning.id] === undefined) {
          setSetup((prevSetup) => {
            return {
              ...prevSetup,
              warningsLoaded: prevSetup.warningsLoaded + 1,
            };
          });
        }
      });

      return { ...prevSetup, warnings: updatedWarnings };
    });

    const floodAreasToGet = [];
    /* From here we are looping through warnings list to create a separate floodAreasToGet array
to get the flood areas of  each warnings by calling getFloodAreas
*/
    for (let i = 0; i < warningList.length; i++) {
      const thisWarning = warningList[i];

      if (setup.shapes[thisWarning.id] === undefined) {
        floodAreasToGet.push(thisWarning);
      } else {
        setSetup((prevSetup) => {
          return { ...prevSetup, warningsLoaded: prevSetup.warningsLoaded + 1 };
        });
      }

      if (floodAreasToGet.length === 10 || i === warningList.length - 1) {
        const floodAreaIds = floodAreasToGet.map((warning) => warning.id);
        getFloodAreas(floodAreaIds);
        floodAreasToGet.length = 0;
      }
    }
  }

  function getFloodAreas(ids) {
    var fa = this;
    //var docRef = gdb.collection("floodAreas").where("fwdCode", "in", ids); //commented this as floodAreas is the table created from v1 code written by Joe

    var docRef = gdb.collection("floodAreasNew").where("fwdCode", "in", ids); //New table floodAreasNew created as per new firebase function created and deployed as part of v2

    var gotIds = [];
    var updatedShapes = {};

    docRef.get().then(function (docs) {
      for (var i = 0; i < docs.docs.length; i++) {
        if (docs.docs[i].exists) {
          var thisFloodArea = docs.docs[i].data();
          var docId = docs.docs[i].id;
          gotIds.push(docId);

          thisFloodArea.areaLabel = thisFloodArea.label;
          if (thisFloodArea.polygon != undefined) {
            delete thisFloodArea.polygon;
          }

          updatedShapes[docId] = thisFloodArea;
          thisFloodArea._id = docId;
        }
      }

      for (var j = 0; j < ids.length; j++) {
        if (gotIds.indexOf(ids[j]) == -1) {
          var thisFloodArea2 = {};
          updatedShapes[ids[j]] = thisFloodArea2;
          thisFloodArea2._id = ids[j];
        }
      }

      setSetup((prevSetup) => ({
        ...prevSetup,
        shapes: { ...prevSetup.shapes, ...updatedShapes },
        warningsLoaded: prevSetup.warningsLoaded + gotIds.length,
      }));
    });
  }

  function handleGeolocationSuccess(position) {
    // Handle the geolocation data
    setSetup((prevSetup) => ({
      ...prevSetup,
      geolocation: {
        ...prevSetup.geolocation,
        lat: position.lat,
        lon: position.long,
      },
      settings: { ...prevSetup.settings, location: true },
    }));
    //console.log("function success geolocation", position.long);
  }

  function handleGeolocationError(error) {
    console.error("Error getting geolocation:", error.message);
    //for showing default map-
    setSetup((prevSetup) => ({
      ...prevSetup,
      geolocation: {
        ...prevSetup.geolocation,
        lat: 52.984,
        lon: -1.975,
        available: false,
      },
      settings: { ...prevSetup.settings, location: true },
    }));
  }

  function getLocation() {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error("Geolocation is not supported by your browser"));
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const lat = position.coords.latitude;
            const long = position.coords.longitude;
            resolve({ lat, long });
          },
          (error) => {
            reject(error);
          },
          { enableHighAccuracy: true }
        );
      }
    });
  }

  function saveSettings() {
    var mySettings = {
      _id: iSettings.id,
      intro: iSettings.intro,
    };

    if (iSettings?.rev != undefined) {
      mySettings._rev = iSettings.rev;
    }
    stdb
      .post(mySettings)
      .then(function (response) {
        iSettings.rev = response.rev;
      })
      .catch(function (err) {
        console.log(err);
      });
  }

  return (
    <div id="floodalert">
      {iSettings.intro ? (
        <Intro />
      ) : (
        <div>
          <FloodAlertMap setup={setup} />
          {setup.showSnippet && <FloodAlertSnippet />}
          <FloodAlertSummary props={setup} />
          <FloodAlertMenu props={setup} />
          <FloodAlertSearch setup={setup} />
        </div>
      )}
    </div>
  );
}
export default App;
