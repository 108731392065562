import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import { createRoot } from "react-dom/client";
import Map, { GeolocateControl, Marker, Popup } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "../styles/FloodAlertMap.scss";
import { Context } from "../Context";
import FloodAlertIcon from "./FloodAlertIcon";
//import InitialMap from "./InitialMap";
//import FloodAlertMapDefault from "./FloodAlertMapDefault";

//---------------------------------------------------------------------------- */

const FloodAlertMap = (props) => {
  const [popups, setPopups] = useState([]);
  const { setup, setSetup, filter, setFilter } = useContext(Context);
  const [map, setMap] = useState();
  const [mapLoaded, setMapLoaded] = useState(false);
  const [searchMarker, setsearchMarker] = useState([]);
  const serviceUrl = "https://api.os.uk/maps/vector/v1/vts";
  const key = "yUKDJ9wS89qUqm8EENGn46e9sPrmNKNA";
  const mapRef = useRef();
  var levelLayers = {};
  const [retainMarker, setRetainMarker] = useState(false);
  const [clickedMarkerId, setClickedMarkerId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [locPermission, setLocPermission] = useState(true);
  //---------------------------------------------------------------------------- */
  //console.log("setup MAP", setup);
  //Initial state as per existing vue app
  const [initialMapState, setInitialMapState] = useState({
    longitude: -1.975,
    latitude: 52.984,
    zoom: 5.76,
    fitBoundsOptions: "true",
  });

  //let say current location-Wales, UK
  /* const [initialMapState, setInitialMapState] = useState({
    longitude: -3.1805,
    latitude: 51.48131,
    //zoom: 5.76,
    zoom: 6.77,
    fitBoundsOptions: "true",
  }); */

  //capture run time location

  //---------------------------------------------------------------------------- */

  /* const [initialMapState, setInitialMapState] = useState({
    longitude: setup.geolocation.lon,
    latitude: setup.geolocation.lat,
    zoom: 6.77,
    fitBoundsOptions: "true",
  }); */
  //console.log("map geolocation", setup.geolocation.lon);

  useEffect(() => {
    console.log("setup MAP", setup);
  }, [mapLoaded]);

  //---------------------------------------------------------------------------- */
  useEffect(() => {
    //console.log("Geolocation information:", setup.geolocation);
    // Check if geolocation information is available
    //setTimeout(() => {
    if (
      props.setup.geolocation &&
      props.setup.geolocation.lon &&
      props.setup.geolocation.lat
    ) {
      // Set the initial map state based on geolocation
      if (props.setup.geolocation.available) {
        setInitialMapState({
          longitude: props.setup.geolocation.lon,
          latitude: props.setup.geolocation.lat,
          zoom: 6.77,
          fitBoundsOptions: "true",
        });
        setLoading(false);
      } else {
        ///Condition written just to adjust zoom of a default map case.
        setInitialMapState({
          longitude: props.setup.geolocation.lon,
          latitude: props.setup.geolocation.lat,
          zoom: 5.76,
          fitBoundsOptions: "true",
        });
        setLoading(false);
      }
    }
    //}, 1000);
  }, [props.setup.geolocation]);
  //---------------------------------------------------------------------------- */

  const onMapLoad = useCallback(() => {
    setMap(mapRef.current.getMap());
    // map = mapRef.current.getMap();.
  }, []);
  //---------------------------------------------------------------------------- */
  useEffect(() => {
    if (setup.resetMap) {
      setClickedMarkerId(null);
      resetMap();
    } else {
    }
  }, [setup.resetMap, setup.resetMapCount]);
  //---------------------------------------------------------------------------- */
  const resetMap = () => {
    setsearchMarker([]); //To remove search results marker from map In case when user search-Go to that loc--click on Home
    setRetainMarker(false); //So that when user click home -- again search and click close button then markers should be removed as in previous step it becomes true

    /* setTimeout(() => {
      setsearchMarker([]); //To remove search results marker from map In case when user search-Go to that loc--click on Home
      setRetainMarker(false); //So that when user click home -- again search and click close button then markers should be removed as in previous step it becomes true
    }, 1000); */
    const popup = document.querySelector(".mapboxgl-popup");
    if (popup) {
      popup.remove();
    }
    if (map && initialMapState) {
      const { longitude, latitude, zoom, fitBoundsOptions } = initialMapState;
      map.easeTo({
        center: [longitude, latitude],
        //center: [-1.975, 52.984],
        zoom: zoom,
        fitBoundsOptions: true,
      });
    }
  };
  //---------------------------------------------------------------------------- */

  const onMapLoadd = () => {};

  //---------------------------------------------------------------------------- */

  useEffect(() => {
    initMap();
  }, [map]);

  //---------------------------------------------------------------------------- */

  const initMap = () => {
    if (map != null && !mapLoaded) {
      var backgroundLayer = map.getLayer("background");

      if (backgroundLayer != undefined) {
        map.setPaintProperty("background", "background-color", "#A9DDEF");
      }

      //Existing code bounds--
      /* map.fitBounds([
        [-5.712803, 49.957901],
        [1.76207, 55.811237],
      ]); */

      //New Bounding box, bounds the map to entire UK
      /* map.fitBounds([
        [-4.5, 53],
        [1.7, 50],
      ]); */

      const { longitude, latitude, zoom, fitBoundsOptions } = initialMapState;
      //Below code added to center the map around user's current location
      map.easeTo({
        center: [longitude, latitude],
        zoom: zoom,
        fitBoundsOptions: true,
      });
      map.addSource("warnings", {
        type: "vector",
        url: "mapbox://floodalert.warningsCombined",
      });
      map.addSource("alerts", {
        type: "vector",
        url: "mapbox://floodalert.alertsCombined",
      });

      map.dragRotate.disable();
      map.touchZoomRotate.disableRotation();

      var levels = [];
      for (var key in props.setup.levels) {
        levels.push({
          name: key,
          level: props.setup.levels[key],
        });
      }
      loadLevelImages(levels, 0, function () {});
      setMapLoaded(true);
      console.log("below line calls update layers ");
      updateLayers();
    }
  };

  //---------------------------------------------------------------------------- */
  //Below use Effect added to update layers as and when shape object changes.. as
  //Somehow shapes getting empty momentarily by some component
  useEffect(() => {
    if (Object.keys(setup.shapes).length > 0 && mapLoaded) {
      updateLayers();
    }
  }, [setup.shapes]);

  //---------------------------------------------------------------------------- */

  const loadLevelImages = (levels, id, callback) => {
    map.loadImage(levels[id].level.hostedIcon, (error, image) => {
      if (error) throw error;
      // map.addImage(levels[id].name, image); //fix for bigger null icons hence commented
    });
  };

  //---------------------------------------------------------------------------- */

  const goToWarning = (key) => {
    if (key != null) {
      var markers = [];

      if (
        props.setup.shapes[key] == undefined ||
        props.setup.shapes[key].polygon == undefined
      ) {
        if (markers[key] != undefined) {
          map.easeTo({
            center: markers[key].getLngLat(),
            padding: props.setup.mapPadding,
            zoom: 11,
          });
        } else {
          //this if condition is written to handle when lat long is null for objects having null icons
          if (
            !isNaN(props.setup.shapes[key]?.long) ||
            !isNaN(props.setup.shapes[key]?.lat)
          ) {
            map.easeTo({
              center: [
                props.setup.shapes[key]?.long,
                props.setup.shapes[key]?.lat,
              ],
              padding: props.setup.mapPadding,
              zoom: 11,
            });
          }
        }
      } else {
      }
    }
  };

  //---------------------------------------------------------------------------- */

  const updateLayers = () => {
    console.log("inside update layers called");
    //console.log("map update layer", map.getLayer("warnings"));
    var shapesLoaded = true;
    //console.log("props.setup.warnings", props.setup.warnings);
    console.log("props.setup.shapes", props.setup.shapes);
    console.log("shapes length", Object.keys(setup.shapes).length);
    //console.log("props.setup", props.setup);
    //console.log("setup", setup);

    for (var key3 in props.setup.warnings) {
      if (props.setup.shapes[key3] == undefined) {
        shapesLoaded = false;
      }
    }
    console.log("inside update layers Shapes Loaded is true?", shapesLoaded);
    if (shapesLoaded == true) {
      var idList = [];
      var alertsIdList = [];
      var warningsIdList = [];
      var severeIdList = [];
      console.log("level layers", levelLayers);
      for (var key5 in levelLayers) {
        console.log("level layers", levelLayers);
        levelLayers[key5].source.data = {
          type: "FeatureCollection",
          features: [],
        };
      }

      for (var key in props.setup.warnings) {
        if (props.setup.shapes[key] != undefined) {
          var myLevel = {};
          for (var key2 in props.setup.levels) {
            if (
              props.setup.levels[key2].level ==
              props.setup.warnings[key].severityLevel
            ) {
              myLevel = props.setup.levels[key2];
              myLevel.name = key2;
            }
          }
          //console.log("setup is", setup);
          if (props.setup.warnings[key].severityLevel != 4) {
            idList.push(key);
          }
          if (props.setup.warnings[key].severityLevel === 1) {
            severeIdList.push(key);
          }
          if (props.setup.warnings[key].severityLevel === 2) {
            warningsIdList.push(key);
          }
          if (props.setup.warnings[key].severityLevel === 3) {
            alertsIdList.push(key);
          }
          //console.log("warningsID are", warningsIdList.length);
          // Find common values

          if (
            props.setup.shapes[key].polygon != undefined &&
            map.getZoom() >= props.setup.polygonMinZoom
          ) {
            console.log("polygon inside check");
            var existingLayer = map.getLayer(key);

            if (existingLayer == undefined) {
              map.addLayer({
                id: key,
                source: {
                  type: "geojson",
                  data: props.setup.shapes[key].polygon,
                },
                type: "fill",
                paint: {
                  "fill-color": myLevel.colour,
                  "fill-opacity": 0.3,
                  "fill-antialias": true,
                },
                layout: {},
                minzoom: props.setup.polygonMinZoom,
              });
            }
          }

          if (levelLayers[myLevel.name] == undefined) {
            levelLayers[myLevel.name] = {
              id: myLevel.name,
              source: {
                type: "geojson",
                data: {
                  type: "FeatureCollection",
                  features: [],
                },
              },
              type: "symbol",
              layout: {
                "icon-image": myLevel.name, // reference the image
                "icon-size": 0.125,
                "icon-allow-overlap": true,
              },
            };
          }

          var myProperties = {
            active: false,
            fwdCode: props.setup.shapes[key].fwdCode,
            FWD_TACODE: props.setup.shapes[key].FWD_TACODE,
          };

          if (key in props.setup.snippets) {
            myProperties.active = true;
          }

          levelLayers[myLevel.name].source.data.features.push({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [
                props.setup.shapes[key].long,
                props.setup.shapes[key].lat,
              ],
            },
            properties: myProperties,
          });
        }
      }
      ////-------------------------------------------------------------------------------------------

      var myFilter = ["match", ["get", "FWD_TACODE"], idList, true, false];
      ////As there are never the case where alerts and warnings ID are comon so below commented code have no use
      /*// Identify common values between alerts and warnings
      let commonValues = new Set(
        alertsIdList.filter((value) => warningsIdList.includes(value))
      );
      // Remove common values from alerts and warnings
      alertsIdList = alertsIdList.filter((value) => !commonValues.has(value));
      warningsIdList = warningsIdList.filter(
        (value) => !commonValues.has(value)
      );

      // Add additional filtering to exclude already filled areas for alerts
      var existingAlerts = map.queryRenderedFeatures({
        layers: ["alerts"],
      });

      var existingAlertsIds = existingAlerts.map(
        (feature) => feature.properties.FWD_TACODE
      );
      alertsIdList = alertsIdList.filter(
        (value) => !existingAlertsIds.includes(value)
      );

      // Add additional filtering to exclude already filled areas for warnings
      var existingWarnings = map.queryRenderedFeatures({
        layers: ["warnings"],
      });

      var existingWarningsIds = existingWarnings.map(
        (feature) => feature.properties.FWD_TACODE
      );
      warningsIdList = warningsIdList.filter(
        (value) => !existingWarningsIds.includes(value)
      );

      // Now use the updated alertsIdList and warningsIdList in the filters
      var alertsFilter = [
        "match",
        ["get", "FWD_TACODE"],
        alertsIdList,
        true,
        false,
      ];
      var warningsFilter = [
        "match",
        ["get", "FWD_TACODE"],
        warningsIdList,
        true,
        false,
      ]; */

      ////-----------------NO use code ends here---------------------------------------------------------------------------------------

      /* if (map.getLayer("alerts") == undefined) {
        map.addLayer({
          id: "alerts",
          type: "fill-extrusion",
          source: "alerts",
          "source-layer": "alerts",
          minzoom: 9,
          paint: {
            "fill-extrusion-color": "#F2A747",
            "fill-extrusion-height": 1,
            "fill-extrusion-opacity": 0.3,
          },
          filter: alertsFilter,
        });
      } else {
        map.setFilter("alerts", alertsFilter);
      }

      if (map.getLayer("warnings") == undefined) {
        map.addLayer({
          id: "warnings",
          type: "fill-extrusion",
          source: "warnings",
          "source-layer": "warnings",
          minzoom: 9,
          paint: {
            "fill-extrusion-color": "#E84952",
            "fill-extrusion-height": 1,
            "fill-extrusion-opacity": 0.3,
          },
          //filter: ["any", warningsFilter, ["!", alertsFilter]],
          filter: warningsFilter,
        });
      } else {
        map.setFilter("warnings", ["any", warningsFilter, ["!", alertsFilter]]);
      } */

      if (map.getLayer("alerts") == undefined) {
        console.log("Inside alerts layers");
        map.addLayer({
          id: "alerts",
          type: "fill",
          source: "alerts",
          "source-layer": "alerts",
          minzoom: 9,
          paint: {
            "fill-color": "#F2A747",
            //"fill-color": "#F2A747",
            "fill-opacity": 0.3,
          },
          filter: myFilter,
        });
      } else {
        map.setFilter("alerts", myFilter);
      }

      if (map.getLayer("warnings") == undefined) {
        map.addLayer({
          id: "warnings",
          type: "fill",
          source: "warnings",
          "source-layer": "warnings",
          minzoom: 9,
          paint: {
            "fill-color": "#E84952",
            "fill-opacity": 0.3,
          },
          filter: myFilter,
        });
      } else {
        map.setFilter("warnings", myFilter);
      }

      /*       if (map.getLayer("alerts-line") == undefined) {
        map.addLayer({
          id: "alerts-line",
          type: "line",
          source: "alerts",
          "source-layer": "alerts",
          minzoom: 9,
          layout: {
            visibility: "none",
          },
          paint: {
            "line-color": "#000000",
            "line-width": 1,
          },
          //filter: myFilter,
          filter: alertsFilter,
        });
      }

      if (map.getLayer("warnings-line") == undefined) {
        map.addLayer({
          id: "warnings-line",
          type: "line",
          source: "warnings",
          "source-layer": "warnings",
          minzoom: 9,
          layout: {
            visibility: "none",
          },
          paint: {
            "line-color": "#000000",
            "line-width": 1,
          },
          //filter: myFilter,
          filter: warningsFilter,
        });
      } */

      for (var key4 in levelLayers) {
        if (map.getSource(key4) == undefined) {
          map.addSource(key4, levelLayers[key4].source);
          map.addLayer({
            id: levelLayers[key4].id,
            type: levelLayers[key4].type,
            layout: levelLayers[key4].layout,
            source: key4,
          });
        } else {
          var existingSource = map.getSource(key4);
          existingSource.setData(levelLayers[key4].source.data);
        }
      }
    }
  };

  //---------------------------------------------------------------------------- */

  const addResultMarker = (geometry) => {
    let dataSearch = [];
    geometry.map((e) => {
      dataSearch.push({
        latitude: e.geometry.coordinates[1],
        longitude: e.geometry.coordinates[0],
        icon: e.icon,
        title: e.title,
      });
    });
    setsearchMarker(dataSearch);
  };

  //---------------------------------------------------------------------------- */

  const handleMarkerClick = (marker, id) => {
    let l = marker.target.getLngLat();
    goToWarning(id);
    setSetup((prevSetup) => ({
      ...prevSetup,
      showSnippet: prevSetup.showSnippet == false ? true : false,
      snippets:
        setup.warnings[setup.shapes[id].fwdCode] !== undefined
          ? [setup.warnings[setup.shapes[id].fwdCode]]
          : [],
    }));

    setClickedMarkerId(id);
  };

  //---------------------------------------------------------------------------- */

  const getpopup = (ele) => {
    let popupNode = document.createElement("div");
    const root = createRoot(popupNode);
    root.render(
      <div>
        <div className="markerIcon"></div>
        <div className="list">
          <div className="item">
            <div className="resultmarker" style={{ marginRight: "1px" }}>
              <FloodAlertIcon icon={ele.icon} />
            </div>
            <div>
              <h2>{ele.title}</h2>
            </div>
          </div>
        </div>
        <button
          className="mapboxgl-popup-close-button"
          type="button"
          aria-label="Close popup"
          aria-hidden="true"
        >
          ×
        </button>
      </div>
    );
    var popup = new mapboxgl.Popup({
      closeButton: true,
    })
      .setLngLat([ele.longitude, ele.latitude])
      .setDOMContent(popupNode);
    setTimeout(() => {
      popup.addTo(map);
      setPopups((prevPopups) => [...prevPopups, popup]);
    }, 1000);
  };

  //---------------------------------------------------------------------------- */

  const createPopup = (ele) => {
    const popupNode = document.createElement("div");

    const root = createRoot(popupNode);
    root.render(
      <div>
        <div className="markerIcon"></div>
        <div className="list">
          <div className="item">
            <div className="resultmarker" style={{ marginRight: "1px" }}>
              <FloodAlertIcon icon={ele.icon} />
            </div>
            <div>
              <h2>{ele.title}</h2>
            </div>
          </div>
        </div>
        <button
          className="mapboxgl-popup-close-button"
          type="button"
          onClick={() => {
            const parent = popupNode.parentNode;
            if (parent) {
              parent.removeChild(popupNode);
              // Remove the popup from the map
              const popup = document.querySelector(".mapboxgl-popup");
              if (popup) {
                popup.remove();
              }
            }
          }}
        >
          ×
        </button>
      </div>
    );

    const newPopup = new mapboxgl.Popup({
      closeButton: false,
    })
      .setLngLat([ele.longitude, ele.latitude])
      .setDOMContent(popupNode);
    setTimeout(() => {
      newPopup.addTo(map);
      setPopups((prevPopups) => [...prevPopups, newPopup]);
    }, 1000);

    // Cleanup when a new popup is created
    return () => {
      newPopup.remove();
      setPopups((prevPopups) =>
        prevPopups.filter((popup) => popup !== newPopup)
      );
    };
  };

  //---------------------------------------------------------------------------- */

  useEffect(() => {
    if (setup.snippets.length > 0) {
      goToWarning(setup.snippets[0].id);
      setClickedMarkerId(setup.snippets[0].id); // So that when user clicks on any warning from list corresponding warning icon gets bigger on map.
      var myFilter = [
        "match",
        ["get", "FWD_TACODE"],
        setup.snippets,
        true,
        false,
      ];
      map.setFilter("alerts-line", myFilter);
      map.setFilter("warnings-line", myFilter);
      if (map) {
        map.setLayoutProperty("alerts-line", "visibility", "visible");
        map.setLayoutProperty("warnings-line", "visibility", "visible");
      } else {
        map.setLayoutProperty("alerts-line", "visibility", "none");
        map.setLayoutProperty("warnings-line", "visibility", "none");
      }
    }
  }, [setup.snippets, setup.showSnippet]);

  //---------------------------------------------------------------------------- */

  useEffect(() => {
    if (setup.search?.results.length > 0) {
      addResultMarker(setup.search?.results);
    } else if (setup.search?.results.length === 0 && retainMarker) {
      //This condition added to retain markers when clicked from search result
      setsearchMarker([...searchMarker]);
    } else {
      // Clear markers from the map when there are no search
      setsearchMarker([]);
      //if there is any open pop up tool tip close them as well
      const popup = document.querySelector(".mapboxgl-popup");
      if (popup) {
        popup.remove();
      }
    }
  }, [setup.search?.results]);

  //---------------------------------------------------------------------------- */

  useEffect(() => {
    if (setup.search?.result != -1) {
      {
        //Meaning only when we click search result it become >-1
        setRetainMarker(true);
        let locData = setup.search?.results[setup.search.result];
        var { geometry } = {};
        if (locData != undefined) {
          ({ geometry } = locData);

          if (geometry.type == "Point") {
            map.easeTo({
              center: [geometry.coordinates[0], geometry.coordinates[1]],
              zoom: 15,
              padding: {
                top: 81,
                left: 0,
                right: 0,
                bottom: 186,
              },
            });
            addResultMarker(setup.search?.results);
            setSetup((prevSetup) => ({
              ...prevSetup,
              search: { result: -1, results: [] },
            }));
          }
        } //Gemotry undefined if bracket
      }
    }
  }, [setup.search]);

  //---------------------------------------------------------------------------- */

  {
    /* <InitialMap /> */
  }
  {
    /* <div className="loader center-screen"></div> */
  }

  //---------------------------------------------------------------------------- */

  return (
    <div className="map">
      {loading && !mapLoaded ? (
        <div className="loader center-screen"></div>
      ) : (
        <Map
          id="mapbox"
          ref={mapRef}
          mapLib={mapboxgl}
          mapboxAccessToken={props.setup.mapboxToken}
          attributionControl="true"
          customAttribution={`&copy; <a href="http://www.ordnancesurvey.co.uk/">Ordnance Survey</a>`}
          boxZoom="true"
          maxZoom="13" // To allow max zoom only till 13
          /* initialViewState={{
            longitude: -3.8351163,
            latitude: 54.4298718,
            zoom: 6.77,
            fitBoundsOptions: "true",
          }} */
          initialViewState={{
            longitude: initialMapState.longitude,
            latitude: initialMapState.latitude,
            zoom: initialMapState.zoom,
            fitBoundsOptions: "true",
          }}
          onLoad={onMapLoad}
          onError={onMapLoadd}
          mapStyle={serviceUrl + "/resources/styles?key=" + key}
          //mapStyle="mapbox://styles/mapbox/streets-v12"
          hash={true} //FOr new functionality asked
          logoPosition="top-right"
          transformRequest={(url) => {
            url += "&srs=3857";
            return {
              url: url,
            };
          }}
        >
          {Object.values(setup.shapes).map((e) => {
            let severityLevel = setup.warnings[e._id].severityLevel;
            let level = setup.levelOrder[severityLevel - 1];
            /*level != "nolonger" conditional check in below return statement ensures 
            that no longer icons should not  be displayed on Map */
            return e.lat != undefined && level != "nolonger" ? (
              <Marker
                longitude={e.long}
                latitude={e.lat}
                key={e._id}
                onClick={(marker) => handleMarkerClick(marker, e._id)}
                id={e._id}
              >
                <div
                  className={`marker1 ${
                    clickedMarkerId === e._id ? "clicked" : ""
                  }`}
                  data-id={e._id}
                >
                  <img
                    className={`marker1 ${
                      clickedMarkerId === e._id ? "clicked" : ""
                    }`}
                    src={require(`../assets/icons/${setup.levels[level].icon}`)}
                    alt="icon"
                  />
                </div>
              </Marker>
            ) : (
              ""
            );
          })}
          {searchMarker.map((ele) => {
            return (
              <Marker
                longitude={ele.longitude}
                latitude={ele.latitude}
                key={ele.title} // Ensure each marker has a unique key
                onClick={() => createPopup(ele)}
              >
                <div className="resultmarker">
                  <FloodAlertIcon icon={ele.icon} />
                </div>
              </Marker>
            );
          })}

          {!loading &&
            initialMapState &&
            mapLoaded &&
            setup.geolocation.available && (
              <Marker
                longitude={initialMapState.longitude}
                latitude={initialMapState.latitude}
              >
                <div className="mapboxgl-user-location-dot"></div>
              </Marker>
            )}
          {setup.geolocation.available && (
            <GeolocateControl position="top-right" />
          )}
        </Map>
      )}
    </div>
  );
};

export default FloodAlertMap;
