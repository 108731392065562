import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faSyncAlt } from "@fortawesome/free-solid-svg-icons";

const FloodAlertIcon = ({ icon }) => {
  const spinningIcons = ["sync", "sync-alt"];
  const shouldSpin = spinningIcons.includes(icon);

  return (
    <FontAwesomeIcon
      icon={icon === "sync" ? faSync : icon}
      fixedWidth
      style={{ pointerEvents: "none" }}
      spin={shouldSpin}
    />
  );
};

export default FloodAlertIcon;
