import React, { useState } from "react";

export const Context = React.createContext();
export const ContextProvider = ({ children }) => {
  const [setup, setSetup] = useState({
    loaded: false,
    warningsToLoad: 0,
    warningsLoaded: 0,
    warningPolygonsLoaded: 0,
    warningPolygonsQueue: [],
    polygonsLoading: 0,
    polygonMinZoom: 8.5,
    //polygonMinZoom: 1,
    mapPadding: {
      top: 81,
      left: 0,
      right: 0,
      bottom: 186,
    },
    levels: {
      nolonger: {
        label: "No longer in force",
        singular: "No longer in force",
        icon: "NoLongerInForce@4x.png",
        iconRaster: "NoLongerInForce@4x.png",
        hostedIcon:
          "https://floodalerticons.s3.eu-west-2.amazonaws.com/NoLongerInForce%404x.png?go=true",
        level: 4,
        colour: "#AFAFAF",
        advice: "",
      },
      alert: {
        label: "Flood Alerts",
        singular: "Flood Alert in force now",
        icon: "FloodAlert@4x.png",
        iconRaster: "FloodAlert@4x.png",
        hostedIcon:
          "https://floodalerticons.s3.eu-west-2.amazonaws.com/FloodAlert%404x.png?go=true",
        level: 3,
        //colour: "#F18700",
        colour: "#F2A747",
        advice: "Flooding is possible - be prepared",
      },
      warning: {
        label: "Flood Warnings",
        singular: "Flood Warning in force now",
        icon: "FloodWarning@4x.png",
        iconRaster: "FloodWarning@4x.png",
        hostedIcon:
          "https://floodalerticons.s3.eu-west-2.amazonaws.com/FloodWarning%404x.png?go=true",
        level: 2,
        //colour: "#E3000F",
        colour: "#E84952",
        advice: "Flooding is expected - immediate action required",
      },
      severe: {
        label: "Severe Flood Warnings",
        singular: "Severe Flood Warning in force now",
        icon: "SevereFloodWarning@4x.png",
        iconRaster: "SevereFloodWarning@4x.png",
        hostedIcon:
          "https://floodalerticons.s3.eu-west-2.amazonaws.com/SevereFloodWarning%404x.png?go=true",
        level: 1,
        //colour: "#89000D",
        colour: "#8C1419",
        advice: "Severe flooding - danger to life",
      },
    },
    levelOrder: ["severe", "warning", "alert", "nolonger"],
    warnings: {},
    shapes: {},
    /* mapboxToken:
          "pk.eyJ1IjoiZmxvb2QtYWxlcnQiLCJhIjoiY2xvbnpkanQwMGpvZjJ2cXNhenlwb3RuayJ9.SK191HS_mr9A3zCIB6QjQA", */
    mapboxToken:
      "pk.eyJ1IjoiZmxvb2RhbGVydCIsImEiOiJjazkxZGs2dXUwMng3M2lud2s5amlwZXU0In0.p-G2syQzbkZ8nNYUZH4grA",
    position: {
      lat: 0,
      lon: 0,
      zoom: 0,
    },
    search: {
      results: [],
      result: -1,
    },
    popups: [],
    menu: {
      active: false,
      detail: null,
    },
    geolocation: {
      available: true,
      lat: 0,
      lon: 0,
    },
    settings: {
      menu: false,
      intro: true,
      checked: false,
      id: "settings",
      location: false,
    },
    snippets: [],
    showSnippet: false,
    resetMap: false,
    resetMapCount: 0,
  });
  const [filter, setFilter] = useState({
    menu: false,
    keys: {
      County: {
        source: "county",
        filter: [],
      },

      Source: {
        source: "riverOrSea",
        filter: [],
      },
    },
  });

  const [iSettings, setISettings] = useState({
    intro: true,
    id: "settings",
  });

  const contextValue = {
    setup,
    setSetup,
    filter,
    setFilter,
    iSettings,
    setISettings,
  };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};
