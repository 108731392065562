import React, { useContext } from "react";
import FloodAlertDetail from "./FloodAlertDetail";
import FloodAlertList from "./FloodAlertList";
import FloodAlertIcon from "./FloodAlertIcon";
import "../styles/Menu.scss";
import { Context } from "../Context";

const FloodAlertMenu = (props) => {
  const { setup, setSetup, filter, setFilter } = useContext(Context);
  return (
    <div>
      {setup.menu.active && (
        <div
          className="menuback"
          onClick={() => {
            setSetup((prevSetup) => ({
              ...prevSetup,
              menu: { active: false, detail: null },
            }));
          }}
        >
          <div className="close">
            <FloodAlertIcon icon="times" />
          </div>
        </div>
      )}
      {setup.menu.active && (
        <div className="menu">
          <FloodAlertList />
        </div>
      )}
      {setup.menu.active && setup.menu.detail !== null && (
        <div className="menuback sideonly"></div>
      )}
      {setup.menu.active && setup.menu.detail !== null && (
        <div className="menu">
          <FloodAlertDetail />
        </div>
      )}
      {setup.menu.active && setup.menu.detail !== null}
      {setup.menu.active && setup.settings.menu && (
        <div className="menu">
          <FloodAlertDetail />
        </div>
      )}
    </div>
  );
};

export default FloodAlertMenu;
