import React, {
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import Fuse from "fuse.js";
import FloodAlertIcon from "./FloodAlertIcon";
import "../styles/Choices.scss";
import { Context } from "../Context";
import { faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const Choices = ({ values, selected, fltLabel }) => {
  const { setup, setSetup, filter, setFilter } = useContext(Context);
  const [inputValue, setInputValue] = useState("");
  const [selectedValues, setSelectedValues] = useState([...selected]);
  const [searchResults, setSearchResults] = useState([]);
  const [showAll, setShowAll] = useState(false);

  const options = {
    keys: ["value"],
  };

  const cancelSearch = () => {
    setSearchResults([]);
    setInputValue("");
  };

  useEffect(() => {
    goFilter(fltLabel);
  }, [selectedValues]);

  const goFilter = (label) => {
    if (label === "County") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        keys: {
          ...prevFilter.keys,
          County: {
            source: "county",
            filter: [...selectedValues],
          },
          Source: {
            ...prevFilter.keys.Source,
          },
        },
      }));
    } else if (label === "Source") {
      setFilter((prevFilter) => ({
        ...prevFilter,
        keys: {
          ...prevFilter.keys,
          County: {
            ...prevFilter.keys.County,
          },
          Source: {
            source: "riverOrSea",
            filter: [...selectedValues],
          },
        },
      }));
    }
  };

  const addRemove = (value) => {
    if (selectedValues.indexOf(value) === -1) {
      setSelectedValues((prevSelected) => [...prevSelected, value]);
    } else {
      setSelectedValues((prevSelected) =>
        prevSelected.filter((val) => val !== value)
      );
    }
    cancelSearch();
  };

  const handleInputChange = useCallback((e) => {
    setInputValue(e.target.value);
  }, []);

  const fuse = useMemo(() => {
    if (values && values.length > 0) {
      return new Fuse(values, options);
    }
    return null;
  }, [values]);

  useEffect(() => {
    if (inputValue && fuse) {
      var result = fuse.search(inputValue);
      if (result) {
        setSearchResults(result.map((r) => r.item));
      }
    }
  }, [inputValue, fuse]);

  return (
    <div>
      <div className="inputHolder">
        {selectedValues.map((v, idx) => (
          <div
            key={idx}
            key2={fltLabel}
            className="selectedItems"
            onClick={() => addRemove(v)}
          >
            {v} <FloodAlertIcon icon={faTimes} />
          </div>
        ))}
        <input type="text" value={inputValue} onChange={handleInputChange} />
        <div
          className="cancelSearch"
          onClick={cancelSearch}
          style={{
            display:
              searchResults.length > 0 || inputValue !== "" ? "block" : "none",
          }}
        >
          <FloodAlertIcon icon={faTimesCircle} />
        </div>
      </div>

      {searchResults.length === 0 ? (
        <div>
          {showAll ? (
            <>
              {(values || []).map((val, idx) => (
                <div
                  key={idx}
                  className="listItem"
                  onClick={() => addRemove(val?.value)}
                >
                  {val?.value} ({val?.count}){" "}
                  <span
                    style={{
                      display:
                        selectedValues.indexOf(val?.value) !== -1
                          ? "inline"
                          : "none",
                    }}
                  >
                    <FloodAlertIcon icon={faTimesCircle} />
                  </span>
                </div>
              ))}
              <div className="listItem" onClick={() => setShowAll(false)}>
                Show less <FloodAlertIcon icon="caret-circle-up" />
              </div>
            </>
          ) : (
            <>
              {(values || []).slice(0, 5).map((val, idx) => (
                <div
                  key={idx}
                  className="listItem"
                  onClick={() => addRemove(val?.value)}
                >
                  {val?.value} ({val?.count}){" "}
                  <span
                    style={{
                      display:
                        selectedValues.indexOf(val?.value) !== -1
                          ? "inline"
                          : "none",
                    }}
                  >
                    <FloodAlertIcon icon={faTimesCircle} />
                  </span>
                </div>
              ))}
              {values?.length > 5 && (
                <div className="listItem" onClick={() => setShowAll(true)}>
                  Show {values?.length - 5} more{" "}
                  <FloodAlertIcon icon="caret-circle-down" />
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <div>
          {searchResults.slice(0, 5).map((res, idx) => (
            <div
              key={idx}
              className="listItem"
              onClick={() => addRemove(res?.value)}
            >
              {res?.value} ({res?.count}){" "}
              <span
                style={{
                  display:
                    selectedValues.indexOf(res?.value) !== -1
                      ? "inline"
                      : "none",
                }}
              ></span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Choices;
