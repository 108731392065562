import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ContextProvider } from "./Context";

const startApp = () => {
  renderReactApp();
};

const renderReactApp = () => {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <ContextProvider>
      <App />
    </ContextProvider>
  );
};

startApp();

reportWebVitals();
