import "../styles/Splashscreen.scss";
import logo from "../assets/logo.svg";
import FloodAlertCurve from "../assets/FloodAlertCurve.svg";
import { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context } from "../Context";
import {
  faMapMarkedAlt,
  faLocationArrow,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
function Intro(props) {
  const { setup, setSetup, filter, setFilter, iSettings, setISettings } =
    useContext(Context);
  const [page, setPage] = useState(0);

  const handleClick = (step) => {
    setPage(step);
  };

  const setParent = () => {
    /* setSetup((prevSetup) => ({
      ...prevSetup,
      //settings: { ...prevSetup.settings, location: true , intro: false}, No need to location true as already managed by getLocation code
      settings: { ...prevSetup.settings, intro: false },
    })); */

    setISettings((prevSetup) => ({
      ...prevSetup,
      intro: false,
    }));
  };

  return (
    <div className="intro">
      <div className="introbox">
        <div className="logo">
          <img src={logo} className="width" />
        </div>
        <div className="pages">
          {page == 0 && (
            <div className="page" id="page1" key="page1">
              <div className="content full">
                <img className="swooshImg" src={FloodAlertCurve} />
              </div>
              {setup.settings.intro && (
                <div
                  className="button"
                  onClick={() => {
                    handleClick(1);
                  }}
                >
                  Continue
                </div>
              )}
            </div>
          )}
          {page == 1 && (
            <div className="page" id="page2" key="page2">
              <div className="content">
                <div className="icon">
                  <FontAwesomeIcon icon={faMapMarkedAlt} />
                </div>
                <h2>Find the latest flood warnings in England and Wales</h2>
              </div>
              <div
                className="button secondary"
                onClick={() => {
                  handleClick(0);
                }}
              >
                Back
              </div>
              <div
                className="button"
                onClick={() => {
                  handleClick(2);
                }}
              >
                Continue
              </div>
            </div>
          )}
          {page == 2 && (
            <div className="page">
              <div className="content">
                <div className="icon">
                  <FontAwesomeIcon icon={faLocationArrow} />
                </div>
                <h2>Use your location to see nearby warnings</h2>
              </div>
              <div
                className="button secondary"
                onClick={() => {
                  handleClick(1);
                }}
              >
                Back
              </div>
              <div
                className="button"
                onClick={() => {
                  handleClick(3);
                }}
              >
                Continue
              </div>
            </div>
          )}
          {page == 3 && (
            <div className="page">
              <div className="content">
                <div className="icon">
                  <FontAwesomeIcon icon={faCheckCircle} />
                </div>
                <h3>
                  Review and accept our{" "}
                  <a
                    href="https://www.floodalert.com/privacy-policy"
                    target="_BLANK"
                  >
                    Terms of Use and Privacy Policy
                  </a>{" "}
                  to start
                </h3>
              </div>
              <div
                className="button secondary"
                onClick={() => {
                  handleClick(2);
                }}
              >
                Back
              </div>
              <div
                className="button"
                onClick={() => {
                  setParent();
                }}
              >
                Accept
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Intro;
