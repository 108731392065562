import React, { useContext, useEffect, useState, useRef } from "react";
import FloodAlertIcon from "./FloodAlertIcon";
import "../styles/FloodAlertSearch.scss";
import { Context } from "../Context";
import axios from "axios";

import {
  faList,
  faSyncAlt,
  faTimes,
  faSearch,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faTram,
  faPlane,
  faWineBottle,
  faFootballBall,
  faFish,
  faLandmark,
  faMonument,
  faBreadSlice,
  faHome,
} from "@fortawesome/free-solid-svg-icons";

import {
  FaMapMarkerAlt,
  FaLocation,
  FaLandmark, // Placeholder, replace with the correct icon
  FaPiggyBank,
  FaGlassMartini,
  FaRoadBarrier,
  FaBaseballBall,
  FaBasketballBall,
  FaBowlFood,
  FaUmbrellaBeach,
  FaBeer,
  FaBiking,
  FaHeart,
  FaBowlingPins, // Placeholder, replace with the correct icon
  FaArchway, // Placeholder, replace with the correct icon
  FaBuilding,
  FaBus,
  FaCoffeeTogo,
  FaCampground,
  FaCar,
  FaGarageCar,
  FaCarMechanic,
  FaCoins,
  FaChessRook,
  FaTombstoneAlt,
  FaChargingStation,
  FaCameraMovie,
  FaCircle,
  FaCity,
  FaClinicMedical,
  FaTshirt,
  FaGraduationCap,
  FaShoppingCart,
  FaBroadcastTower,
  FaCookie,
  FaShoppingBasket,
  FaCricket,
  FaTimes,
  FaWater,
  FaExclamationCircle,
  FaHeartbeat,
  FaTooth,
  FaDog,
  FaGlass,
  FaFlag,
  FaPhonePlus,
  FaSignInAlt,
  FaFarm,
  FaBurgerSoda,
  FaArrowsAltV, // Placeholder, replace with the correct icon
  FaShip,
  FaFireAlt,
  FaDumbbell,
  FaFlowerDaffodil,
  FaGasPump,
  FaChair,
  FaGamepadAlt,
  FaTrees,
  FaSeedling,
  FaGift,
  FaGlobeEurope,
  FaGolfBall,
  FaCut,
  FaAnchor,
  FaHammer,
  FaHelicopter,
  FaHouse,
  FaHorseSaddle,
  FaHospital,
  FaIndustryAlt, // Placeholder, replace with the correct icon
  FaInfoCircle,
  FaGem,
  FaMicrophoneStand,
  FaMap, // Placeholder, replace with the correct icon
  FaWasher,
  FaBooks,
  FaLightbulb, // Placeholder, replace with the correct icon
  FaBed,
  FaTree, // Placeholder, replace with the correct icon
  FaMobileAlt,
  FaMountain,
  FaUniversity,
  FaMusic,
  FaLeaf,
  FaGlasses,
  FaBrush,
  FaParking,
  FaPrescriptionBottleAlt,
  FaBlanket,
  FaPlaceOfWorship,
  FaPlay,
  FaSirenOn,
  FaEnvelope,
  FaDungeon,
  FaTrain,
  FaSubway,
  FaUserCowboy,
  FaRecycle,
  FaVihara,
  FaChurch,
  FaSynagogue,
  FaMosque,
  FaToriiGate,
  FaHome,
  FaUtensilsAlt,
  FaSoup,
  FaPizzaSlice,
  FaFishCooked,
  FaConstruction,
  FaRocket,
  FaSchool,
  FaMotorcycle,
  FaHomeHeart,
  FaBoot,
  FaStore,
  FaSnowboarding,
  FaSkiing,
  FaSteak,
  FaSnowmobile,
  FaFutbol,
  FaSquare,
  FaStar,
  FaSuitcase,
  FaSwimmer,
  FaTableTennis,
  FaTaco,
  FaMugTea,
  FaPhone,
  FaRacquet,
  FaTheaterMasks,
  FaToilet,
  FaLandmarkAlt,
  FaTriangle,
  FaPaw,
  FaBinoculars,
  FaVolcano,
  FaVolleyballBall,
  FaWarehouseAlt,
  FaTrash,
  FaWatch,
  FaDuck,
  FaWheelchair,
  FaWindTurbine,
  FaElephant,
  FaMonument,
  FaFish,
} from "react-fontawesome";

const FloodAlertSearch = (props) => {
  const { setup, setSetup, filter, setFilter } = useContext(Context);
  //-----------------------------------------------------------------------------------------------
  const searchRef = useRef(null);
  const [search, setSearch] = useState({
    text: "",
    lastText: "",
    searching: false,
    waiting: false,
    active: false,
  });

  const [icons, setIcons] = useState({
    //country: "flag-alt",
    region: FaMapMarkerAlt,
    postcode: FaLocation,
    district: FaMapMarkerAlt,
    place: FaMapMarkerAlt,
    locality: FaMapMarkerAlt,
    neighborhood: FaMapMarkerAlt,
    address: faHome,
    poi: faMapMarkerAlt,

    aerialway: faTram,
    airfield: faPlane,
    airport: faPlane,
    "alcohol-shop": faWineBottle,
    "american-football": faFootballBall,
    "amusement-park": FaLandmark, // Placeholder, replace with the correct icon
    aquarium: faFish,
    "art-gallery": faLandmark,
    attraction: faMonument,
    bakery: faBreadSlice,
    bank: FaPiggyBank,
    "bank-JP": FaPiggyBank,
    bar: FaGlassMartini,
    barrier: FaRoadBarrier,
    baseball: FaBaseballBall,
    basketball: FaBasketballBall,
    bbq: FaBowlFood,
    beach: FaUmbrellaBeach,
    beer: FaBeer,
    bicycle: FaBiking,
    "blood-bank": FaHeart,
    "bowling-alley": FaBowlingPins, // Placeholder, replace with the correct icon
    bridge: FaArchway, // Placeholder, replace with the correct icon
    building: FaBuilding,
    "building-alt": FaBuilding,
    bus: FaBus,
    cafe: FaCoffeeTogo,
    campsite: FaCampground,
    car: FaCar,
    "car-rental": FaGarageCar,
    "car-repair": FaCarMechanic,
    casino: FaCoins,
    castle: FaChessRook,
    "castle-JP": FaChessRook,
    cemetery: FaTombstoneAlt,
    "cemetary-JP": FaTombstoneAlt,
    "charging-station": FaChargingStation,
    cinema: FaCameraMovie,
    circle: FaCircle,
    "circle-stroked": FaCircle,
    city: FaCity,
    clinic: FaClinicMedical,
    "clothing-store": FaTshirt,
    college: FaGraduationCap,
    "college-JP": FaGraduationCap,
    commercial: FaShoppingCart,
    "communications-tower": FaBroadcastTower,
    confectionery: FaCookie,
    convenience: FaShoppingBasket,
    cricket: FaCricket,
    cross: FaTimes,
    dam: FaWater,
    danger: FaExclamationCircle,
    defibrillator: FaHeartbeat,
    dentist: FaTooth,
    doctor: FaClinicMedical,
    "dog-park": FaDog,
    "drinking-water": FaGlass,
    embassy: FaFlag,
    "emergency-phone": FaPhonePlus,
    entrance: FaSignInAlt,
    "entrance-alt": FaSignInAlt,
    farm: FaFarm,
    "fast-food": FaBurgerSoda,
    fence: FaArrowsAltV, // Placeholder, replace with the correct icon
    ferry: FaShip,
    "fire-station": FaFireAlt,
    "fire-station-JP": FaFireAlt,
    "fitness-centre": FaDumbbell,
    florist: FaFlowerDaffodil,
    fuel: FaGasPump,
    furniture: FaChair,
    gaming: FaGamepadAlt,
    garden: FaTrees,
    "garden-centre": FaSeedling,
    gift: FaGift,
    globe: FaGlobeEurope,
    golf: FaGolfBall,
    grocery: FaShoppingBasket,
    hairdresser: FaCut,
    harbor: FaAnchor,
    hardware: FaHammer,
    heart: FaHeart,
    heliport: FaHelicopter,
    home: FaHouse,
    "hore-riding": FaHorseSaddle,
    hospital: FaHospital,
    "hospital-JP": FaHospital,
    "ice-cream": FaHospital, // Placeholder, replace with the correct icon
    industry: FaIndustryAlt, // Placeholder, replace with the correct icon
    information: FaInfoCircle,
    "jewelry-store": FaGem,
    karaoke: FaMicrophoneStand,
    landmark: FaLandmark,
    "landmark-JP": FaLandmark,
    landuse: FaMap, // Placeholder, replace with the correct icon
    laundry: FaWasher,
    library: FaBooks,
    lighthouse: FaLightbulb, // Placeholder, replace with the correct icon
    lodging: FaBed,
    logging: FaTree, // Placeholder, replace with the correct icon
    marker: FaMapMarkerAlt,
    "marker-stroked": FaMapMarkerAlt,
    "mobile-phone": FaMobileAlt,
    monument: FaMonument,
    mountain: FaMountain,
    museum: FaUniversity,
    music: FaMusic,
    natural: FaLeaf,
    optician: FaGlasses,
    paint: FaBrush,
    park: FaTrees,
    "park-alt": FaTrees,
    parking: FaParking,
    "parking-garage": FaParking,
    pharmacy: FaPrescriptionBottleAlt,
    "picnic-site": FaBlanket,
    pitch: FaFutbol, // Placeholder, replace with the correct icon
    "place-of-worship": FaPlaceOfWorship,
    playground: FaPlay, // Placeholder, replace with the correct icon
    police: FaSirenOn,
    "police-JP": FaSirenOn,
    post: FaEnvelope,
    "post-JP": FaEnvelope,
    prison: FaDungeon,
    rail: FaTrain,
    "rail-light": FaSubway,
    "rail-metro": FaSubway,
    "ranger-station": FaUserCowboy,
    recycling: FaRecycle,
    "religious-buddhist": FaVihara,
    "religious-christian": FaChurch,
    "religious-jewish": FaSynagogue,
    "religious-muslim": FaMosque,
    "religious-shinto": FaToriiGate,
    "residential-community": FaHome,
    restaurant: FaUtensilsAlt,
    "restaurant-noodle": FaSoup,
    "restaurant-pizza": FaPizzaSlice,
    "restaurant-seafood": FaFishCooked,
    roadblock: FaConstruction,
    rocket: FaRocket,
    school: FaSchool,
    "school-JP": FaSchool,
    scooter: FaMotorcycle,
    shelter: FaHomeHeart,
    shoe: FaBoot,
    shop: FaStore,
    skateboard: FaSnowboarding,
    skiing: FaSkiing,
    slaughterhouse: FaSteak,
    slipway: FaAnchor,
    snowmobile: FaSnowmobile,
    soccer: FaFutbol,
    square: FaSquare,
    "square-stroked": FaSquare,
    stadium: FaFutbol,
    star: FaStar,
    "star-stroked": FaStar,
    suitcase: FaSuitcase,
    sushi: FaFish,
    swimming: FaSwimmer,
    "table-tennis": FaTableTennis,
    taco: FaTaco,
    teahouse: FaMugTea,
    telephone: FaPhone,
    tennis: FaRacquet,
    theatre: FaTheaterMasks,
    toilet: FaToilet,
    town: FaCity,
    "town-hall": FaLandmarkAlt,
    triangle: FaTriangle,
    "triangle-stroked": FaTriangle,
    veterinary: FaPaw,
    viewpoint: FaBinoculars,
    village: FaHouse,
    volcano: FaVolcano,
    volleyball: FaVolleyballBall,
    warehouse: FaWarehouseAlt,
    "waste-basket": FaTrash,
    watch: FaWatch,
    water: FaWater,
    waterfall: FaWater,
    watermill: FaIndustryAlt, // Placeholder, replace with the correct icon
    wetland: FaDuck,
    wheelchair: FaWheelchair,
    windmill: FaWindTurbine,
    zoo: FaElephant,
  });

  const guid = () => {
    let dt = new Date().getTime();
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      (c) => {
        let r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  };

  const preSearch = () => {
    if (search.text.length > 3) {
      if (!search.searching) {
        setSearch((prevSearch) => ({
          ...prevSearch,
          searching: true,
        }));
        goSearch();
      } else {
        setSearch((prevSearch) => ({
          ...prevSearch,
          waiting: true,
        }));
      }
    } else {
      setSetup((prevSetup) => ({
        ...prevSetup,
        search: { ...prevSetup.search, results: [] },
      }));
    }
  };

  const goSearch = async () => {
    console.log("go search called");
    try {
      const thisSearch = search.text;

      let searchUrl =
        "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
        search.text +
        ".json?access_token=" +
        setup.mapboxToken +
        "&cachebuster=1586805202670&autocomplete=true" +
        "&country=gb";

      if (setup.position.zoom >= 9) {
        console.log("yes zoom level is >9");
        console.log("yes zoom level is >9 lon", setup.position.lon);
        console.log("yes zoom level is >9 lat", setup.position.lat);

        searchUrl +=
          "&proximity=" + setup.position.lon + "%2C" + setup.position.lat;
      }

      setSetup((prevSetup) => ({
        ...prevSetup,
        search: { ...prevSetup.search, result: -1, results: [] },
      }));

      const response = await axios.get(searchUrl);
      console.log(response);

      setSetup((prevSetup) => ({
        ...prevSetup,
        search: {
          ...prevSetup.search,
          results: response.data.features.map((feature) => {
            let subtitle = "";
            for (let j = 0; j < feature.context.length; j++) {
              if (feature.context[j].text !== feature.text) {
                if (subtitle !== "") {
                  subtitle += ", ";
                }
                subtitle += feature.context[j].text;
              }
            }

            let type = feature.place_type;
            if (feature.properties !== undefined) {
              if (feature.properties.category !== undefined) {
                const types = feature.properties.category.split(",");
                let thisType = "";
                let k = -1;

                for (k = types.length - 1; k >= 0; k--) {
                  thisType = types[k].trim().split(" ");
                  for (let l = 0; l < thisType.length; l++) {
                    type.unshift(thisType[l]);
                  }
                }
                for (k = types.length - 1; k >= 0; k--) {
                  thisType = types[k].trim().replace(" ", "-");
                  type.unshift(thisType);
                }
              }
              if (feature.properties.maki !== undefined) {
                type.unshift(feature.properties.maki);
              }
            }
            console.log("type is ", type);
            return {
              uid: guid(),
              title: feature.text,
              subtitle: subtitle,
              type: type,
              icon: resultIcon(type),
              geometry: feature.geometry,
            };
          }),
        },
      }));

      if (search.waiting && search.text !== thisSearch) {
        setSearch((prevSearch) => ({
          ...prevSearch,
          waiting: false,
        }));
        goSearch();
      } else {
        setSearch((prevSearch) => ({
          ...prevSearch,
          searching: false,
        }));
        ////Commented out below code to fix the issue when There is just one search result It auto zooms to loaction-- example england
        /*         if (setup.search.results.length === 1) {
          setSetup((prevSetup) => ({
            ...prevSetup,
            search: { ...prevSetup.search, result: 0 },
          }));
          console.log("Set up search 2",setup.search);
        } */
      }
    } catch (error) {
      console.log(error);
    }
  };

  const activateSearch = () => {
    searchRef.current.focus();
    searchRef.current.select();
    preSearch();
  };

  const clearSearch = () => {
    searchRef.current.blur();
    setSearch((prevSearch) => ({ ...prevSearch, text: "" }));
    setSetup((prevSetup) => ({
      ...prevSetup,
      search: { ...prevSetup.search, results: [] },
    }));
  };

  const resultIcon = (type) => {
    let myIcon = faMapMarkedAlt;

    for (let i = 0; i < type.length; i++) {
      if (icons[type[i]] !== undefined) {
        myIcon = icons[type[i]];
        break; // No need to continue searching once a match is found
      }
    }

    return myIcon;
  };

  useEffect(() => {
    preSearch();
  }, [search.text]);

  //------------------------------------------------------------------------------------------------
  return (
    <div className="searchHolder">
      {setup.search.results.length > 0 && (
        <div className="results panel">
          {setup.search.results.map((result, idx) => (
            <div
              key={idx}
              className="result"
              onClick={() =>
                setSetup({ ...setup, search: { ...setup.search, result: idx } })
              }
            >
              <div className="resultIcon">
                {console.log("icon", result.icon)}
                <FloodAlertIcon icon={result.icon} />
              </div>
              <div className="resultText">
                <h3>{result.title}</h3>
                <h4>{result.subtitle}</h4>
              </div>
            </div>
          ))}
        </div>
      )}

      <div className="search panel">
        {!setup.menu.active && (
          <div
            className="home"
            onClick={() => {
              setSetup({
                ...setup,
                resetMap: true,
                resetMapCount: { ...(setup.resetMapCount + 1) },
              });
            }}
          >
            <FloodAlertIcon icon={faHome} />
          </div>
        )}
        {setup.menu.active && (
          <div
            className="home"
            onClick={() => {
              setSetup({
                ...setup,
                menu: { ...setup.menu, active: false },
                resetMap: true,
                resetMapCount: { ...(setup.resetMapCount + 1) },
              });
            }}
          >
            <FloodAlertIcon icon={faHome} />
          </div>
        )}

        {!setup.menu.active && (
          <div
            className="menuIcon"
            onClick={() => {
              setSetup({
                ...setup,
                menu: { ...setup.menu, active: true },
                search: { ...setup.search, results: [] },
              });
            }}
          >
            <FloodAlertIcon
              icon={setup.polygonsLoading === 0 ? faList : faSyncAlt}
            />
          </div>
        )}

        {setup.menu.active && (
          <div
            className="menuIcon"
            onClick={() => {
              setSetup({
                ...setup,
                menu: { ...setup.menu, active: false, detail: null },
                settings: { menu: false },
                search: { ...setup.search, results: [] },
              });
            }}
          >
            <FloodAlertIcon
              icon={setup.polygonsLoading === 0 ? faMapMarkedAlt : faSyncAlt}
            />
          </div>
        )}

        <div className="bar"></div>
        <input
          type="text"
          value={search.text}
          ref={searchRef}
          placeholder="Search (Minimum 4 Chars)"
          onChange={(e) => setSearch({ ...search, text: e.target.value })}
          onFocus={activateSearch}
        />

        {!setup.search.results.length && !setup.menu.active && (
          <div className="menuIcon pale" onClick={activateSearch}>
            <FloodAlertIcon icon={faSearch} />
          </div>
        )}

        {setup.search.results.length > 0 && (
          <div className="menuIcon pale" onClick={clearSearch}>
            <FloodAlertIcon icon={faTimes} />
          </div>
        )}
      </div>
    </div>
  );
};

export default FloodAlertSearch;
