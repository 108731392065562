import React, { useContext, useEffect, useMemo, useState } from "react";
import "../styles/FloodAlertCount.scss";
import FloodAlertIcon from "./FloodAlertIcon";
import { Context } from "../Context";

const FloodAlertCount = ({ props, type }) => {
  const { setup, setSetup, filter, setFilter } = useContext(Context);
  const icon = setup?.levels?.[type]?.icon
    ? require(`../assets/icons/${setup.levels[type].icon}`)
    : null;

  const getCount = useMemo(() => {
    let myCount = 0;
    for (const key in setup.warnings) {
      if (setup.warnings[key].severityLevel === setup.levels[type].level) {
        myCount += 1;
      }
    }
    return myCount;
  }, [setup.warnings]);

  return (
    <div
      className="count"
      onClick={() => {
        setSetup((prevSetup) => ({
          ...prevSetup,
          menu: { ...prevSetup.menu, active: true },
        }));
      }}
    >
      {setup.loaded ? (
        <div className="number">{getCount}</div>
      ) : (
        <div className="number rotating">
          <FloodAlertIcon icon="sync" />
        </div>
      )}
      <div
        className={`warningIcon ${
          getCount === 0 && setup.loaded ? "inactive" : ""
        }`}
      >
        {icon && <img src={icon} height="56" />}
      </div>

      <div className="label">{setup.levels?.[type]?.label}</div>
    </div>
  );
};

export default FloodAlertCount;
