import React, { useContext } from "react";
import FloodAlertIcon from "./FloodAlertIcon";
import moment from "moment";
import "../styles/FloodAlertDetail.scss";
import { Context } from "../Context";
import IconComponent from "./IconComponent";

import {
  faArrowLeft,
  faArrowRight,
  faMapMarkedAlt,
  faExternalLinkAlt,
  faInfoCircle,
  faExclamationTriangle,
  faLifeRing,
  faPhoneFlip,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

const FloodAlertDetail = ({ props }) => {
  const { setup, setSetup, filter, setFilter } = useContext(Context);

  const myLevel = () => {
    for (const key in setup.levels) {
      if (
        setup.levels[key].level ===
        setup.warnings[setup.menu.detail?.id]?.severityLevel
      ) {
        return setup.levels[key];
      }
    }
    return null;
  };
  const timeString = () => {
    const myTime = Math.max(
      setup.warnings[setup.menu.detail?.id]?.timeMessageChanged,
      setup.warnings[setup.menu.detail?.id]?.timeRaised,
      setup.warnings[setup.menu.detail?.id]?.timeSeverityChanged
    );
    return moment(myTime).format("MMMM Do YYYY, h:mm:ss a");
  };

  const getMessage = (msg) => {
    if (msg) {
      const splitMsg = msg.split(". ");
      return splitMsg
        .map(
          (sentence, index) =>
            `<p key=${index} style="margin-bottom: 10px;">${sentence}${
              index < splitMsg.length - 1 ? "." : ""
            }</p>`
        )
        .join(" ");
    }
    return "";
  };

  const openLink = (link) => {
    window.open(link);
  };

  return (
    <div className="listview">
      <div className="fixed">
        <div className="topblock"></div>
        <div className="list sticky">
          <div
            className="item filter clickable"
            onClick={() => {
              setSetup((prevSetup) => ({
                ...prevSetup,
                menu: { ...prevSetup.menu, detail: null },
                settings: { ...prevSetup.settings, menu: false },
              }));
            }}
          >
            <h3>
              <IconComponent icon={faArrowLeft} /> Back to warnings
            </h3>
          </div>
        </div>
      </div>
      <div className="scrollable">
        <div
          className="list"
          style={{ display: setup.settings.menu === false ? "block" : "none" }}
        >
          <div className="item">
            <h1>{setup.shapes[setup.menu.detail?.id]?.label}</h1>
          </div>
          <div
            className="item header inverse"
            style={{ backgroundColor: myLevel() && myLevel().colour }}
          >
            <img
              src={myLevel() && require(`../assets/icons/${myLevel().icon}`)}
              alt={myLevel() && myLevel().label}
            />
            <div>
              <h2>{myLevel() && myLevel().singular}</h2>
              <h3 className="advice">
                {myLevel() && myLevel().advice !== "" && myLevel().advice}
              </h3>
            </div>
          </div>
          <div
            className="item header clickable"
            onClick={() => {
              setSetup((prevSetup) => ({
                ...prevSetup,
                //menu: { active: false, ...prevSetup.menu.detail },
                //1st dec changes for adhoc bug fix
                menu: { active: false, detail: null },
                showSnippet: true, //With this I'm only showing snippet when clicked on view on Map not like existing app where its loaded in DOM even when view on map not clicked
              }));
            }}
            style={{ textAlign: "right" }}
          >
            <div style={{ width: "100%" }}>
              <h3>
                <IconComponent icon={faMapMarkedAlt} /> View on the map
                <IconComponent icon={faArrowRight} />
              </h3>
            </div>
          </div>
          <div
            className="item noflow"
            dangerouslySetInnerHTML={{
              __html: getMessage(
                setup.warnings[setup.menu.detail?.id]?.message
              ),
            }}
          ></div>
          <div className="item noflow">
            <p>This information was last updated at {timeString()}</p>
          </div>
          <div className="item noflow"></div>
          {/* {Object.entries(setup.levelOrder).map(([level, idx]) => {
            if (level && idx !== undefined) {
            return(
            <div className="list" key={idx}>
              <div className="item header">
                <img
                    src={setup.levels[level]?.icon && require(`../assets/icons/${setup.levels[level].icon}`)}
                    alt={(setup.levels[level]?.label || '')}
                />
                
                {setup.levels[level].label &&(
                <h2>{setup.levels[level].label}</h2>)}
              </div>
              {setup.filteredResults[level].count === 0 ? (
                <div className="item">
                  <img src={require(`../assets/icons/${setup.levels['nolonger'].icon}`)} alt={setup.levels['nolonger'].label} />
                  <h2>There are currently no {setup.levels[level].label} in force</h2>
                </div>
              ) : (
                setup.filteredResults[level].warnings.map((item, k2) => (
                  <div className="item" key={k2}>
                    <img src={require(`../assets/icons/${setup.levels[level].icon}`)} alt={setup.levels[level].label} />
                    {setup.shapes[k2] !== undefined && <h2>{setup.shapes[k2].label}</h2>}
                  </div>
                ))
              )}
            </div>
          );
            }else{
                return null;
            }
        })} */}
        </div>
        <div
          className="list"
          style={{ display: setup.settings.menu === true ? "block" : "none" }}
        >
          <div className="item">
            <h1>
              <FloodAlertIcon icon={faInfoCircle} /> About
            </h1>
          </div>
          <div className="item">
            <p>
              At{" "}
              <a href="https://www.jacobs.com/" target="_BLANK">
                Jacobs
              </a>
              , as global leaders in{" "}
              <a
                href="https://www.jacobs.com/capabilities/water"
                target="_BLANK"
              >
                water management
              </a>{" "}
              we make the world smarter, more connected and more sustainable.
              Our{" "}
              <a href="https://www.floodmodeller.com" target="_BLANK">
                Flood Modeller
              </a>{" "}
              technology is at the heart of the national flood forecasting
              services in England, Wales and Scotland. Flood Alert applies our
              industry-leading knowledge of flood forecasting and warning
              alongside our expertise in scalable web technology.
            </p>
          </div>
          <div className="item">
            <h3>
              Live flood warning data, visualised, simplified, and accessible.
            </h3>
          </div>
          <div
            className="item clickable"
            onClick={() => openLink("https://www.jacobs.com")}
          >
            <h3>
              <FloodAlertIcon icon={faExternalLinkAlt} />
            </h3>
            <h3>Find out more about Jacobs</h3>
          </div>
          <div>
            <div
              className="item clickable"
              onClick={() => openLink("https://www.floodmodeller.com")}
            >
              <h3>
                <FloodAlertIcon icon={faExternalLinkAlt} />
              </h3>
              <h3>Find out more about Flood Modeller</h3>
            </div>

            <div className="item">
              <h1>
                <FloodAlertIcon icon={faExclamationTriangle} /> Flood warnings
              </h1>
            </div>

            <div className="item">
              <p>
                Flood Alert is not designed to replace official flood warning
                services. Sign up for flood warnings:
              </p>
            </div>

            <div
              className="item clickable"
              onClick={() =>
                openLink("https://www.gov.uk/sign-up-for-flood-warnings")
              }
            >
              <h3>
                <FloodAlertIcon icon={faExternalLinkAlt} />
              </h3>
              <h3>England</h3>
            </div>

            <div
              className="item clickable"
              onClick={() =>
                openLink(
                  "https://naturalresources.wales/flooding/sign-up-to-receive-flood-warnings/?lang=en"
                )
              }
            >
              <h3>
                <FloodAlertIcon icon={faExternalLinkAlt} />
              </h3>
              <h3>Wales</h3>
            </div>

            <div
              className="item clickable"
              onClick={() =>
                openLink("http://floodline.sepa.org.uk/floodingsignup/")
              }
            >
              <h3>
                <FloodAlertIcon icon={faExternalLinkAlt} />
              </h3>
              <h3>
                Scotland
                <br />
                (not currently covered by Flood Alert)
              </h3>
            </div>

            <div className="item">
              <h1>
                <FloodAlertIcon icon={faLifeRing} /> Advice and support
              </h1>
            </div>

            <div className="item">
              <p>UK Government:</p>
            </div>

            <div
              className="item clickable"
              onClick={() =>
                openLink("https://www.gov.uk/prepare-for-flooding")
              }
            >
              <h3>
                <FloodAlertIcon icon={faExternalLinkAlt} />
              </h3>
              <h3>Prepare for flooding</h3>
            </div>

            <div
              className="item clickable"
              onClick={() => openLink("https://www.gov.uk/help-during-flood")}
            >
              <h3>
                <FloodAlertIcon icon={faExternalLinkAlt} />
              </h3>
              <h3>Get help during a flood</h3>
            </div>

            <div
              className="item clickable"
              onClick={() => openLink("https://www.gov.uk/after-flood")}
            >
              <h3>
                <FloodAlertIcon icon={faExternalLinkAlt} />
              </h3>
              <h3>What to do after a flood</h3>
            </div>

            <div className="item">
              <p>Natural Resources Wales:</p>
            </div>

            <div
              className="item clickable"
              onClick={() =>
                openLink(
                  "https://naturalresources.wales/flooding/what-to-do-before-during-and-after-a-flood/?lang=en"
                )
              }
            >
              <h3>
                <FloodAlertIcon icon={faExternalLinkAlt} />
              </h3>
              <h3>What to do before, during and after a flood</h3>
            </div>

            <div
              className="item clickable"
              onClick={() =>
                openLink(
                  "https://naturalresources.wales/flooding/preparing-your-home-business-or-farm-for-a-flood/?lang=en"
                )
              }
            >
              <h3>
                <FloodAlertIcon icon={faExternalLinkAlt} />
              </h3>
              <h3>How to prepare your home, business or farm for a flood</h3>
            </div>

            <div class="item">
              <p>Other support:</p>
            </div>

            <div
              className="item clickable"
              onClick={() => openLink("https://nationalfloodforum.org.uk/")}
            >
              <h3>
                <FloodAlertIcon icon={faExternalLinkAlt} />
              </h3>
              <h3>National Flood Forum</h3>
            </div>

            <div className="item">
              <h1>
                <FloodAlertIcon icon={faPhoneFlip} /> Contact us
              </h1>
            </div>

            <div
              className="item clickable"
              onClick={() =>
                openLink("mailto:support@floodalert.com?subject=Flood Alert")
              }
            >
              <h3>
                <FloodAlertIcon icon={faEnvelope} />
              </h3>
              <h3>support@floodalert.com</h3>
            </div>

            <div
              className="item clickable"
              onClick={() => openLink("https://www.floodalert.com")}
            >
              <h3>
                <FloodAlertIcon icon={faExternalLinkAlt} />
              </h3>
              <h3>Flood Alert website</h3>
            </div>

            <div className="item">
              <p>
                Jacobs is not responsible for the content of external links.
              </p>
            </div>

            <div className="item">
              <p>Flood Alert © Jacobs 2024.</p>
            </div>

            <div className="item noflow"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FloodAlertDetail;
